import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnRelationLink from './columnRelationLink'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0014 = (props: Props): JSX.Element => {
  const routePram = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0013/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0014'
  )

  return (
    <Wrapper>
      <ColumnH1 label="客先常駐での労働時間の管理や36協定の扱いはどう対応すべき？" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.04.15</p>
      <section>
        <img src="/images/column/details/c0014.jpg" alt="キンクラコラム" />
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          IT業界では比較的一般的とされる<span>客先常駐</span>
          ですが、雇用契約を交わしている会社で勤務する通常の社員とは働き方が異なるため、労務管理をどのように行えば良いか悩むケースも多いようです。特に、常駐先で働く時間や残業の管理については、誤った対応をしてしまうと法律違反を問われる危険性もみられます。
          <br />
          <br />
          今回は、客先常駐社員の<span>労働時間の管理や、36協定の取り扱い</span>
          について、順を追って解説しましょう。
        </p>
        <div className="toc">
          <p>目次</p>
          <ol>
            <li>
              <a href="#toc1">客先常駐とは何か</a>
              <ul>
                <li>
                  <a href="#toc1-1">客先常駐の種類</a>
                </li>
                <li>
                  <a href="#toc1-2">請負契約とは</a>
                </li>
                <li>
                  <a href="#toc1-3">準委任契約とは</a>
                </li>
                <li>
                  <a href="#toc1-4">派遣との違いは</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#toc2">客先常駐の労働時間管理</a>
            </li>
            <li>
              <a href="#toc3">客先常駐の36協定管理</a>
            </li>
            <li>
              <a href="#toc4">まとめ</a>
            </li>
          </ol>
        </div>
        <div id="toc1">
          <h1>客先常駐とは何か</h1>
        </div>
        <p>
          客先常駐とは、雇用契約を交わしている会社の指示により、別の会社へ出勤をして働くことをいいます。
          <br />
          IT企業におけるシステムエンジニアや、税理士などの士業、経営コンサルタントなど、専門性の高い職種の者がその知識を如何なく発揮し、クライアントひいては自社のために働く仕組みを取っています。
        </p>
        <div id="toc1-1">
          <h2>客先常駐の種類</h2>
        </div>
        <p>
          客先常駐には、主に請負契約と準委任契約の2種類が挙げられます。
          <br />
          どちらも他社で常駐しながら働くということに変わりはありませんが、契約や報酬の形態が大きく異なります。
        </p>
        <div id="toc1-2">
          <h2>請負契約とは</h2>
        </div>
        <p>
          請負契約とは、<span>注文を受けた業務を最後まで完成させること</span>
          を条件に、契約を交わす方式です。
          <br />
          たとえば、パッケージデザインの制作を希望するA社が、デザイン会社であるB社との間で請負契約が交わされるとします。この場合、B社からデザイナーである社員がA社へ客先常駐という形態で常駐し、デザインが完成するまでの作業を請け負います。
          <br />
          請負契約は、あくまでも最終目的は<span>「成果物の完成」</span>
          となるため、もしもデザイナーが制作したデザインが想定していた出来でなかった場合は、修正作業を行い、A社が納得のいくデザインを作り上げる必要があります。
        </p>
        <div id="toc1-3">
          <h2>準委任契約とは</h2>
        </div>
        <p>
          準委任契約とは、専門的な技術を持つ者を求める会社と、専門的な技術を持つ者が所属する会社との間で交わされる契約です。
          <br />
          成果物の完成を目的とした請負契約とは異なり、労働力の提供が目的となるため、労働の対価となる対象は
          <span>「労働時間」</span>
          です。つまり、客先常駐社員が働けば働くほど、労働量に見合った報酬を支払わなければなりません。
        </p>
        <div id="toc1-4">
          <h2>派遣との違いは</h2>
        </div>
        <p>
          所属する会社とは別企業で働く形態として、「派遣契約」を想像する方も多いことでしょう。
          <br />
          派遣契約は、派遣元企業と派遣先企業との間で派遣契約が交わされた上で、派遣社員が派遣先企業で勤務をする雇用形態となりますが、派遣先が業務の指示を受けるのは派遣先企業となります。
          <br />
          一方、請負契約や準委任契約の場合、
          <span>
            客先常駐を行う社員が業務の指示を受ける相手は、所属元の企業
          </span>
          です。常駐先の企業は、契約を交わしたクライアントという位置づけとなり、常駐社員に仕事の指示を行うことはできません。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc2">
          <h1>客先常駐の労働時間管理</h1>
        </div>
        <p>
          前述の通り、請負契約や準委任契約の場合は、客先常駐社員への指揮命令権は所属先の企業となります。つまり、客先常駐社員の労働管理の義務があるのは当然ながら所属先企業です。
          <br />
          仕事の指示を出しておきながら、その社員の労働管理を怠るのはあってはならない事でしょう。
          <br />
          <br />
          しかし、客先常駐社員の働き方については、常駐先の企業のルールに影響を受けることも事実です。たとえば、勤務時間や休憩、休日の取扱いなどは、会社によって大きく異なります。
          <br />
          <br />
          このような状況に対応するため、客先常駐社員の所属元会社は、常駐をさせる前にあらかじめ常駐先会社と、社員をどのような形で常駐させるかを入念に話し合っておく必要があります。
          <br />
          客先常駐社員は所属元の会社と雇用契約を交わしている社員であるため、法定労働時間や法定休日を超えて働かせる必要性が生じた場合は、当然ながら所属元会社が報酬に上乗せして割増賃金を支払う必要があります。また、別の環境で常駐する社員の健康管理のためにも、
          <span>休日や有給休暇の付与、定期的な健康診断の実施</span>
          についての管理も求められます。
        </p>
        <div id="toc3">
          <h1>客先常駐の36協定管理</h1>
        </div>
        <p>
          社員に定時時間を超えて残業をさせる必要がある場合は、
          <span>36協定届の締結・届け出</span>
          が必要となります。36協定届とは、会社に所属する社員に法定労働時間や法定休日を超えて残業や休日出勤をさせる場合に、会社・社員間で取り交わした上で届け出を行う書類のことです。
          <br />
          法定労働時間とは、法律で定められた社員の労働時間の上限のことで、一日あたり8時間、一週間あたり40時間が原則です。また、法定休日とは、同じく法律で定められた社員に与えるべき最低限の休日数をいい、具体的には1週間あたり1日以上（4週間の中で4日以上）です。
          <br />
          <br />
          客先常駐社員の場合も同様で、常駐先で残業や休日出勤をする場合には36協定届が必要になります。この場合に協定届を届け出る責任を持つのは、前項目における労働時間管理の場合と同じく、
          <span>所属元の企業</span>です。
        </p>
        <ColumnRelationLink
          link={routePram}
          label="残業に必要な36協定、「知らなかった」では済まされない基礎知識"
        />
        <div id="toc4">
          <h1 className="read">まとめ</h1>
        </div>
        <p>
          客先常駐は、専門的な知識を持つ戦力を欲する依頼側の企業、移動費をかけることなく社員を常駐先で業務に集中させることができる受託側の企業、双方にさまざまなメリットがあるシステムです。
          <br />
          一方で、常に会社に出勤しているわけではないという環境から、常駐社員の労働時間が目に見えにくいという懸念点があることも念頭においた上で、
          <span>適切な労務管理</span>
          を進めなければならないという点についても覚えておきましょう。
        </p>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0014
